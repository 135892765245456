<template>
  <v-app>
    <TopBar />
    <router-view id="my_body"/>
    <Footer/>
  </v-app>
</template>

<script>
import TopBar from './components/TopBar.vue' 
import Footer from './components/Footer.vue' 

export default {
  name: "App",
  components: {
    TopBar,
    Footer
  },
};
</script>

<style>
 @font-face {
  font-family: "Gilroy-Bold";
  src: local("Fonts/Gilroy-Bold"),
    url(./Fonts/Gilroy-Bold.ttf) format("truetype"); 
}
 @font-face {
  font-family: "Gilroy-ExtraBold";
  src: local("Fonts/Gilroy-ExtraBold"),
    url(./Fonts/Gilroy-ExtraBold.ttf) format("truetype"); 
}
 @font-face {
  font-family: "Gilroy-Light";
  src: local("Fonts/Gilroy-Light"),
    url(./Fonts/Gilroy-Light.ttf) format("truetype"); 
}
 @font-face {
  font-family: "Gilroy-Medium";
  src: local("Fonts/Gilroy-Medium"),
    url(./Fonts/Gilroy-Medium.ttf) format("truetype"); 
}
 @font-face {
  font-family: "Gilroy-SemiBold";
  src: local("Fonts/Gilroy-SemiBold"),
    url(./Fonts/Gilroy-SemiBold.ttf) format("truetype"); 
}

html {
  box-sizing: border-box;
  min-width: 900px;
}
*,
::after,
::before {
  box-sizing: inherit;
}
body {
  text-rendering: optimizeLegibility;
  margin: 0;
  padding: 0;
}
.color_blue{
  color: #3576CB; 
}
.bg_blue{
  background-color: #3576CB; 
}
#my_body{
  min-height: calc(100vh - 279px);
}

</style>
