<template>
  <div class="poptavka">
    <div class="nadpis color_blue">POPTÁVKA</div>
    <form class="form" method="post" action="sendEmail.php">
      <input v-model="firma" type="text" name="name" />
      <label for="name">Firma</label>
      <input v-model="osoba" type="text" name="kont_osoba" />
      <label for="kont_osoba">Kontaktní osoba</label>
      <input v-model="email" type="email" name="email" />
      <label for="email">Email</label>
      <input v-model="phone" type="tel" name="telefon" />
      <label for="telefon">Telefon</label>
      <br />
      <div class="prepinace">
        <label for="typ_vymeniku">Automatický paletový výměník</label>
        <input v-model="automat_typ" type="radio" name="typ_vymeniku" value="Ano" />
        <label for="typ_vymeniku">Manuální paletový výměník</label>
        <input v-model="manual_typ" type="radio" name="typ_vymeniku" value="Ano" />
      </div>
      <input v-model="midaco_typ" type="text" name="midaco_typ" />
      <label for="midaco_typ">Typ MIDACO (nepovinné)</label>
      <input v-model="ke_stroji" type="text" name="pripojit_ke_stroji" />
      <label for="pripojit_ke_stroji"
        >Plánujeme připojit ke stroji (výrobce + označení)</label
      >
      <textarea v-model="poznamka" type="text" name="poznamka" />
      <label for="poznamka">Poznámka</label>
      <div class="odeslat_btn">
        <btn v-on:click="odeslat" class="odeslat bg_blue">Odeslat</btn>
      </div>
    </form>
    <v-dialog v-model="dialog" width="360">
      <v-card>
        <v-card-text class="card_text" >
          {{ dialogText }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  created() {
          this.midaco_typ = this.$route.params.typ;
        },
  data(){
    return{
      firma: '',
      osoba: '',
      email: '',
      phone: '',
      automat_typ: '',
      manual_typ: '',
      midaco_typ: '',
      ke_stroji: '',
      poznamka: '',
      dialog: false,
      dialogText: '',
      typ: ''
    }
  },
  methods: {
    odeslat(){
      if(this.osoba == '' || this.email == ''){
        this.dialogText = "Prosím vyplňte alespoň jméno kontaktní osoby a e-mail.";
        this.dialog = true;
      } else 
      
        axios
        .post("https://midaco.cz/sendMail.php", {
          firma: this.firma,
          osoba: this.osoba,
          email: this.email,
          phone: this.phone,
          automat_typ: this.automat_typ,
          manual_typ: this.manual_typ,
          midaco_typ: this.midaco_typ,
          ke_stroji: this.ke_stroji,
          poznamka: this.poznamka
        })
        .then(() => {this.dialogText = "Formulář byl odeslán.";
            this.dialog = true;
            this.firma = '';
            this.osoba = '';
            this.email = '';
            this.phone = '';
            this.automat_typ = '';
            this.manual_typ = '';
            this.midaco_typ = '';
            this.ke_stroji = '';
            this.poznamka = '';
        });
      }
  }
 
};
</script>

<style scoped>
.poptavka {
  display: flex;
  width: 900px;
  max-width: 900px;
  height: auto;
  min-height: 100vh;
  padding-top: 100px;
  margin: 0 auto 0 auto;
}
.poptavka_block {
  width: 100%;
}
.nadpis {
  flex: 0 0 25%;
  font-family: Gilroy-ExtraBold;
  font-size: 20px;
}
.form {
  flex: 0 0 65%;
  font-family: Gilroy-Light;
  font-size: 15px;
}
input {
  width: 100%;
  height: 26px;
  padding-left: 10px;
  background-color: lightgray;
  margin-top: 8px;
}
textarea {
  width: 100%;
  height: 100px;
  padding-left: 10px;
  background-color: lightgray;
  margin-top: 10px;
}
label {
  font-family: Gilroy-Medium;
  font-size: 16px;
}
.prepinace {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 10px 0;
  padding: 0;
}
input[type="radio"] {
  width: 19px;
  margin: 0 10px;
}
.odeslat {
  font-size: 18px;
  font-family: Gilroy-ExtraBold;
  color: white;
  padding: 4px 20px 4px 20px;
}
.odeslat_btn {
  text-align: right;
}
btn {
  border-radius: 5px;
  cursor: pointer;
}
.v-dialog > .v-card > .v-card__text {
  padding: 20px;
}
.card_text {
  font-size: 24px;
  text-align: center;
}
@media (max-width: 960px){
  .poptavka {
    margin: 0 20px 0 20px;
  }
}
</style>