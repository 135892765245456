<template>
  <div class="automaticke">
    <div class="nadpis_block" justify="center" align="center">
      <div class="nadpis color_blue">AUTOMATICKÉ PALETOVÉ VÝMĚNÍKY</div>
       <a
          class="jaktofunguje"
          href="https://youtu.be/HeWSkR8sW3A"
          target="blank"
          >( jak to funguje?
          <img
            class="youtube_logo"
            src="../assets/logo-youtube.png"
            alt="logo youtube"
          />
          )</a
        >
    </div>

    <div class="automaticke_main" v-for="(stroj, index) in stroje" :key="index">
      <div class="sub_nadpis color_blue">
        {{ stroj.nadpis }}
      </div>
      <div class="automaticke_obsah">
        <div class="img_block">
          <img :src="require(`../assets/Pics/${stroj.imgname}`)" alt="stroj" />
        </div>

        <div class="right_block">
          <table>
            <tr>
              <td>Velikost palety (X):</td>
              <td>{{ stroj.par1 }}</td>
            </tr>
            <tr>
              <td>Velikost palety (Y):</td>
              <td>{{ stroj.par2 }}</td>
            </tr>
            <tr>
              <td>Maximální zatížení stolu:</td>
              <td>{{ stroj.par3 }}</td>
            </tr>
            <tr>
              <td>Cena (bez DPH) od:</td>
              <td>{{ stroj.par4 }}</td>
            </tr>
          </table>
          <div justify="center" align="center">
          <router-link :to="{ name: 'Poptavka', params: { typ: stroj.nadpis } }">
          <a class="zjistit_vice_btn bg_blue">Poptat</a>
          </router-link>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stroje: [
        { nadpis: "16 CE Series", imgname: "16CE.jpg", par1: "od 559 do 689 mm", par2: "od 356 do 381mm", par3: "až 227 kg", par4: "759 657 Kč" },
        { nadpis: "30 CE Series", imgname: "16CE.jpg", par1: "762 mm", par2: "od 394 do 483 mm", par3: "až 454 kg", par4: "905 980 Kč" },
        { nadpis: "40 CE Series", imgname: "40CE.jpg", par1: "1016 mm", par2: "od 483 do 610 mm", par3: "až 454 kg", par4: "937 067 Kč" },
        { nadpis: "S50 'Stretch' CE Series", imgname: "40CE.jpg", par1: "1270 mm", par2: "od 483 do 609,6 mm", par3: "až 908 kg", par4: "1 003 449 Kč" },
        { nadpis: "50 CE Series", imgname: "40CE.jpg", par1: "1270 mm", par2: "609,6 mm", par3: "až 1816 kg", par4: "1 043 738 Kč" },
        { nadpis: "60 CE Series", imgname: "60CE.jpg", par1: "od 1524 do 1626 mm", par2: "od 610 do 991 mm", par3: "až 1816 kg", par4: "1 403 052 Kč" },
        { nadpis: "80 CE Series", imgname: "80CE.jpg", par1: "od 2032 do 2134 mm", par2: "od 737 do 991 mm", par3: "až 3632 kg", par4: "2 069 058 Kč" },
        { nadpis: "120 CE Series", imgname: "120CE.jpg", par1: "3048 mm", par2: "od 737 do 991 mm", par3: "až 3632 kg", par4: "3 078 210 Kč" },
        { nadpis: "150 CE Series", imgname: "120CE.jpg", par1: "3048 mm", par2: "od 787 do 1245 mm", par3: "až 3632 kg", par4: "4 213 398 Kč" },
        
      ],
    };
  },
};
</script>

<style scoped>
.automaticke {
  margin: 0 auto 0 auto;
}
.nadpis_block{
  margin-bottom: 50px;
}
.nadpis {
  font-family: Gilroy-ExtraBold;
  font-size: 30px;
  margin: 50px 0 0 0;
  border-radius: 10px;
}
.youtube_logo {
  width: 40px;
  transform: translateY(3px);
}
a.jaktofunguje{
  color: gray !important;
}
.automaticke_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 700px;
}
.automaticke_obsah {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sub_nadpis {
  flex: 1 1 100%;
  text-align: center;
  font-size: 22px;
  font-family: Gilroy-ExtraBold;
}
.img_block {
  flex: 1 1 50%;
}
img {
  width: 100%;
}
.right_block {
  flex: 1 1 50%;
}
table {
  margin-bottom: 20px;
}
td {
  font-size: 14px;
  font-family: Gilroy-SemiBold;
}
td:nth-child(2) {
  font-family: Gilroy-Medium;
  padding-left: 10px;
}
.zjistit_vice_btn {
  font-size: 15px;
  font-family: Gilroy-ExtraBold;
  padding: 3px 13px 3px 13px;
}
a {
  color: white !important;
  border-radius: 5px;
  text-decoration: none;
}
</style>