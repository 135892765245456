<template>
  <div class="kontakt">
    <div class="nadpis color_blue">KONTAKT</div>
    <div class="adresa">
      <div class="semi_nadpis"> Jáchym Důjka</div>
      <br>
      <a href="mailto:jachym.dujka@midaco.cz">jachym.dujka@midaco.cz</a><br>
      +420 739 052 491<br>
      <br>
      IČ: 05324424<br>
      DIČ: CZ05324424<br>
      Lidická 700,
      602 00 Brno
    </div>
    <div class="banka">
      <div class="semi_nadpis">Bankovní spojení</div>
      CZK:<br>
      4314728369/0800<br>
      IBAN: CZ03 0800 0000 0043 1472 8369<br>
      <br>
      USD:<br>
      2013913203/0800<br>
      IBAN: CZ46 0800 0000 0020 1391 3203
    </div>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>
.kontakt {
  display: flex;
  width: 900px;
  max-width: 900px;
  padding-top: 100px;
  margin: 0 auto 0 auto;
}
.kontakt_block {
  width: 100%;
  height: auto;
}
.nadpis {
  flex: 0 0 33.3%;
  font-family: Gilroy-ExtraBold;
  font-size: 20px;
}
.semi_nadpis{
  font-family: Gilroy-SemiBold;
  font-size: 15px;
}
.adresa {
  flex: 0 0 33.3%;
  font-family: Gilroy-Light;
  font-size: 15px;
}
.banka{
   flex: 0 0 33.3%;
  font-family: Gilroy-Light;
  font-size: 15px; 
}
@media (max-width: 960px){
  .kontakt {
    margin: 0 20px 0 20px;
  }
}
</style>