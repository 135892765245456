<template>
  <div class="manualni">
    <div class="nadpis_block" justify="center" align="center">
      <div class="nadpis color_blue">MANUÁLNÍ PALETOVÉ VÝMĚNÍKY</div>
        <a
          class="jaktofunguje"
          href="https://youtu.be/ybv1A_ntpDY"
          target="blank"
          >( jak to funguje?
          <img
            class="youtube_logo"
            src="../assets/logo-youtube.png"
            alt="logo youtube"
          />
          )</a
        >
    </div>

    <div class="manualni_main" v-for="(stroj, index) in stroje" :key="index">
      <div class="sub_nadpis color_blue">
        {{ stroj.nadpis }}
      </div>
      <div class="manualni_obsah">
        <div class="img_block">
          <img :src="require(`../assets/Pics/${stroj.imgname}`)" alt="stroj" />
        </div>

        <div class="right_block">
          <table>
            <tr>
              <td>Velikost palety (X):</td>
              <td>{{ stroj.par1 }}</td>
            </tr>
            <tr>
              <td>Velikost palety (Y):</td>
              <td>{{ stroj.par2 }}</td>
            </tr>
            <tr>
              <td>Maximální zatížení stolu:</td>
              <td>{{ stroj.par3 }}</td>
            </tr>
            <tr>
              <td>Cena (bez DPH) od:</td>
              <td>{{ stroj.par4 }}</td>
            </tr>
          </table>
          <div justify="center" align="center">
            <router-link :to="{ name: 'Poptavka', params: { typ: stroj.nadpis } }">
              <a class="zjistit_vice_btn bg_blue">Poptat</a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
  data() {
    return {
      stroje: [
        {
          nadpis: "10FL Series",
          imgname: "manualni.jpg",
          par1: "406 mm",
          par2: "356 mm",
          par3: "až 454 kg",
          par4: "375 382 Kč",
        },
        {
          nadpis: "20FL Series",
          imgname: "manualni.jpg",
          par1: "od 508 do 559 mm",
          par2: "od 406 do 508 mm",
          par3: "až 136 kg",
          par4: "388 710 Kč",
        },
        {
          nadpis: "30FL Series",
          imgname: "manualni.jpg",
          par1: "762 mm",
          par2: "od 406 do 508 mm",
          par3: "až 454 kg",
          par4: "553 078 Kč",
        },
        {
          nadpis: "40FL Series",
          imgname: "manualni.jpg",
          par1: "od 1016 do 1143 mm",
          par2: "od 508 do 635 mm",
          par3: "až 454 kg",
          par4: "597 502 Kč",
        },
        {
          nadpis: "50FL Series",
          imgname: "manualni.jpg",
          par1: "1270 mm",
          par2: "od 508 do 762 mm",
          par3: "až 454 kg",
          par4: "664 138 Kč",
        },
        {
          nadpis: "60FL Series",
          imgname: "manualni.jpg",
          par1: "1524 mm",
          par2: "od 635 do 762 mm",
          par3: "až 454 kg",
          par4: "886 103 Kč",
        },
      ],
    };
  },
};
</script>

<style scoped>
.manualni {
  margin: 0 auto 0 auto;
}
.nadpis_block {
  margin-bottom: 50px;
}
.youtube_logo {
  width: 40px;
  transform: translateY(3px);
}
.nadpis {
  font-family: Gilroy-ExtraBold;
  font-size: 30px;
  margin: 50px 0 0 0;
  border-radius: 10px;
}
a.jaktofunguje {
  align-items: center;
  color: gray !important;
}
.manualni_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 700px;
}
.manualni_obsah {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sub_nadpis {
  flex: 1 1 100%;
  text-align: center;
  font-size: 18px;
  font-family: Gilroy-ExtraBold;
}
.img_block {
  flex: 1 1 50%;
}
img {
  width: 70%;
}
.right_block {
  flex: 1 1 50%;
}
table {
  margin-bottom: 20px;
}
td {
  font-size: 14px;
  font-family: Gilroy-SemiBold;
}
td:nth-child(2) {
  font-family: Gilroy-Medium;
  padding-left: 10px;
}
.zjistit_vice_btn {
  font-size: 15px;
  font-family: Gilroy-ExtraBold;
  padding: 3px 13px 3px 13px;
}
a {
  color: white !important;
  border-radius: 5px;
  text-decoration: none;
}
</style>