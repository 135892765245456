<template>
  <div class="home">
    <div class="video_block">
      <div class="video_frame">
        <video
          class="video"
          src="../assets/PromoVideo.mp4"
          alt="video"
          autoplay
          loop
          muted
        ></video>
      </div>
      <div class="intro">
        <img src="../assets/MidacoCZ_Logo.png" alt="logo_intro" />
        <div>
          Jsme výhradní obchodně-technické zastoupení americké firmy MIDACO
          Corp.<br />
          specializující se na paletové výměníky k vertikálním obráběcím centrům
          od roku 1969. <br />
          Na českém trhu působíme od roku 2018.
        </div>
      </div>
    </div>

    <v-row no-gutters justify="center" align="center">
      <div class="home_nadpis bg_blue">PALETOVÉ VÝMĚNÍKY</div>
    </v-row>
    <div class="home_main">
      <v-col cols="6" justify="center" align="center" class="automaticke">
        <router-link to="/Automaticke">
          <div class="sub_nadpis color_blue">AUTOMATICKÉ</div>
        </router-link>
        <div class="img_aut main_img">
          <img src="../assets/Pics/automaticke.jpg" alt="stroj-automaticke" />
        </div>
        <table>
          <tr>
            <td>Velikost stolu (X):</td>
            <td>od 559 do 3632 mm</td>
          </tr>
          <tr>
            <td>Velikost stolu (Y):</td>
            <td>od 356 do 1245 mm</td>
          </tr>
          <tr>
            <td>Maximální zatížení stolu:</td>
            <td>až 3632 kg</td>
          </tr>
          <tr>
            <td>Aplikace ke stroji:</td>
            <td>ze strany (možnost volby strany)</td>
          </tr>
          <tr>
            <td>Vhodné pro:</td>
            <td>3-osé a 4-osé</td>
          </tr>
        </table>
        <router-link to="/Automaticke">
          <a class="zjistit_vice_btn bg_blue" href="">Zjistit více</a>
        </router-link>
      </v-col>
      <v-col cols="6" justify="center" align="center" class="manualni">
        <router-link to="/Manualni">
          <div class="sub_nadpis color_blue">MANUÁLNÍ</div>
        </router-link>
        <div class="img_man main_img">
          <img src="../assets/Pics/manualni.jpg" alt="stroj-manualni" />
        </div>
        <table>
          <tr>
            <td>Velikost stolu (X):</td>
            <td>od 406,4 do 1524 mm</td>
          </tr>
          <tr>
            <td>Velikost stolu (Y):</td>
            <td>od 355,6 do 762 mm</td>
          </tr>
          <tr>
            <td>Maximální zatížení stolu:</td>
            <td>až 454 kg</td>
          </tr>
          <tr>
            <td>Aplikace ke stroji:</td>
            <td>zepředu</td>
          </tr>
          <tr>
            <td>Vhodné pro:</td>
            <td>3-osé</td>
          </tr>
        </table>
        <router-link to="/Manualni">
          <a class="zjistit_vice_btn bg_blue" href="">Zjistit více</a>
        </router-link>
      </v-col>
    </div>
    <div justify="center" align="center">
      <div class="centra_seznam_nadpis color_blue">
        KOMPATIBILNÍ VERTIKÁLNÍ OBRÁBĚCÍ CENTRA
      </div>
      <img
        src="../assets/Pics/kompatibilnicentra.png"
        alt="kompatibilni centra"
        class="centra_img"
      />
    </div>
    <div class="bottom_text" justify="center" align="center">
      Nenašli jste Váš stroj? Konktakujte nás a prověříme kompatibilitu.
    </div>
    <div justify="center" align="center">
      <router-link :to="{ name: 'Poptavka', params: { typ: '' } }">
        <a class="zjistit_vice_btn bg_blue" href="">Poptat</a>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.home {
}
.video_block {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.video_frame {
  display: flex;
  justify-content: center;
  background-image: linear-gradient(#3576cb, white);
  width: 100vw;
  max-height: 100vh;
}
.video {
  max-height: 100vh;
  max-width: 100vw;
  border: none;
}
.intro {
  display: flex;
  position: relative;
  top: -50px;
  width: 700px;
  height: 100px;
  background-color: white;
  border-radius: 10px;
  padding: 15px 30px;
  margin-left: auto;
  margin-right: auto;
  font-family: Gilroy-Medium;
  font-size: 14px;
}
.intro > img {
  height: 90px;
  padding-right: 10px;
}
.home_nadpis {
  font-family: Gilroy-ExtraBold;
  font-size: 20px;
  color: white;
  margin: 20px 50px;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
}
.sub_nadpis {
  font-size: 18px;
  font-family: Gilroy-ExtraBold;
}
.home_main {
  display: flex;
  max-width: 900px;
  margin: 0 auto 0 auto;
}
.main_img {
  display: flex;
  width: 100%;
  height: 300px;
  justify-content: center;
  align-items: center;
}
.img_man > img {
  width: 70%;
}
.img_aut > img {
  width: 100%;
}
.centra_img {
  width: 100%;
  max-width: 100%;
}
table {
  margin-bottom: 20px;
}
td {
  font-size: 12px;
  font-family: Gilroy-SemiBold;
}
td:nth-child(2) {
  font-family: Gilroy-Medium;
  padding-left: 10px;
}
.zjistit_vice_btn {
  font-size: 20px;
  font-family: Gilroy-ExtraBold;
  padding: 3px 13px 3px 13px;
}
a {
  color: white !important;
  border-radius: 5px;
  text-decoration: none;
}
.centra_seznam_nadpis {
  margin: 20px;
  font-size: 18px;
  font-family: Gilroy-ExtraBold;
}
.centra_img {
  max-width: 900px;
  margin: 0 auto 0 auto;
}
.bottom_text {
  font-family: Gilroy-Light;
  color: gray;
  margin: 20px;
}
@media (max-width: 1062px){
  .video_frame {
    width: 100%;
  }
  .video {
    max-height: none;
    max-width: none;
    width: 100%;
  }
}

</style>