<template>
  <div class="prodistributory">
    <div class="nadpis color_blue">PRO DISTRIBUTORY CNC STROJŮ</div>
    <div class="text">
      Chcete svým zákazníkům nabídnout naše paletizační zařízení?<br>
      Rádi s Vámi budeme kooperovat.<br>
      Pojďme si domluvit schůzku a zjistit, jak a za jakých
      podmínek můžeme úspěšně spolupracovat.
    </div>
    <div class="nadpis color_blue">KONTAKTUJTE NÁS</div>
    <div class="text">
      Jáchym Důjka<br>
      <a href="mailto:jachym.dujka@midaco.cz">jachym.dujka@midaco.cz</a><br>
      +420 739 052 491<br>
      </div>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>
.prodistributory {
  width: 900px;
  height: 100vh;
  max-width: 900px;
  height: 500px;
  padding-top: 100px;
  margin: 0 auto 0 auto;
}
.kontakt_block {
  width: 100%;
}
.nadpis {
  flex: 0 0 100%;
  font-family: Gilroy-ExtraBold;
  font-size: 20px;
  margin-bottom: 25px;
}
.text {
  font-family: Gilroy-Light;
  font-size: 15px;
  margin-bottom: 50px;
}
@media (max-width: 960px){
  .prodistributory {
    margin: 0 20px 0 20px;
  }
}
</style>