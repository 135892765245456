<template>
  <div>
    <div class="navbar">
      <router-link to="/">
        <div class="logo_nadpis color_blue">
          MIDACO
          <span class="logo_nadpis_vpravo color_blue">Czech Republic</span>
        </div>
      </router-link>
      <v-spacer></v-spacer>
      <div class="telefon">+420 739 052 491</div>
      <img @click.stop="drawer = !drawer" src="../assets/humbIcon.png" />
    </div>

    <v-navigation-drawer class="drawer" v-model="drawer" absolute right temporary width='auto' height='auto'>
      <v-list nav >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
          class="side_menu bg_blue"
        >
          <router-link to="/">
            <v-list-item>
              <v-list-item-title>O nás</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/Automaticke">
            <v-list-item>
              <v-list-item-title
                >Automatické paletové výměníky</v-list-item-title
              >
            </v-list-item>
          </router-link>

          <router-link to="/Manualni">
            <v-list-item>
              <v-list-item-title>Manuální paletové výměníky</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/ProDistributory">
            <v-list-item>
              <v-list-item-title>Pro distributory CNC strojů</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link :to="{ name: 'Poptavka', params: { typ: ' ' } }">
            <v-list-item>
              <v-list-item-title>Poptávka</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/Kontakt">
            <v-list-item>
              <v-list-item-title>Kontakt</v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <div class="horizontal_line"></div>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 100px;
  margin: 0 auto 0 auto;
}

.horizontal_line {
  border-bottom: 1px solid #3576cb;
}
.side_menu {
  padding: 25px;
}
/* .drawer{
    right: 0;
    left: 
  } */
.v-list--nav {
  padding: 0;
}
.v-list-item__title {
  font-size: 15px !important;
  color: white;
  font-family: Gilroy-Light;
}
.logo_nadpis {
  font-size: 35px;
  font-family: Gilroy-ExtraBold;
}
.logo_nadpis_vpravo {
  font-family: Gilroy-Light;
}
.telefon {
  font-family: Gilroy-Light;
  color: gray;
}
img {
  width: 50px;
  margin: 20px;
  cursor: pointer;
}
a {
  text-decoration: none;
}
@media (max-width: 960px){
  .navbar {
    padding: 0 10px 0 10px;
  }
}
@media (max-width: 360px){
  .v-list-item__title {
    font-size: 45px !important;
    margin: 30px 0;
  }
 
}
</style>

