import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Automaticke from '../components/Automaticke.vue'
import Manualni from '../components/Manualni.vue'
import Poptavka from '../components/Poptavka.vue'
import ProDistributory from '../components/ProDistributory.vue'
import Kontakt from '../components/Kontakt.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Automaticke',
    name: 'Automaticke',
    component: Automaticke
  },
  {
    path: '/Manualni',
    name: 'Manualni',
    component: Manualni
  },
  {
    path: '/Poptavka/:typ',
    name: 'Poptavka',
    component: Poptavka 
  },
  {
    path: '/ProDistributory',
    name: 'ProDistributory',
    component: ProDistributory 
  },
  {
    path: '/Kontakt',
    name: 'Kontakt',
    component: Kontakt
  },

]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    window.scrollTo(0, 0)
  }
})


export default router
