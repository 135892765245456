import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueAnalytics from 'vue-analytics';

Vue.config.productionTip = false
Vue.use(VueAxios, axios)

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-195141388-1',
  router
});

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
