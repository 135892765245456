<template>
  <div class="footer">
    <div class="footer_body">
      <router-link to="/">
      <a href="">O nás</a> <br />
      </router-link>
      <router-link to="/Automaticke">
      <a href="">Automatické paletové výměníky</a> <br />
      </router-link>
      <router-link to="/Manualni">
      <a href="">Manuální paletové výměníky</a> <br />
      </router-link>
      <router-link to="/ProDistributory">
      <a href="">Pro distributory CNC strojů</a> <br />
      </router-link>
      <router-link to="/Poptavka">
      <a href="">Poptávka</a> <br />
      </router-link>
      <router-link to="/Kontakt">
      <a href="">Kontakt</a> <br />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  background-color: gray;
  color: white;
  font-size: 12px;
  margin-top: 20px;
}
.footer_body{
  width: 900px;
  margin: 0 auto 0 auto;
  padding: 25px 0 ;
}
a {
  font-family: Gilroy-Light;
  color: white !important;
  text-decoration: none;
}
@media (max-width: 960px){
  .footer_body {
    padding: 20px;
  }
}
</style>